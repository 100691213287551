.mobileNavWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 91;
}
.mobileNav {
  z-index: 2;
  width: 80%;
  /* background-color: #6b0001; */
  background-color: #6b0001;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.mobileNav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 15px;
}
.mobileNav ul li {
  padding: 8px 0;
}
.mobileNav ul li a {
  text-decoration: none;
  color: white;
}
.mobileNav ul li:hover {
  background-color: #a30508 !important;
}
.menuLabel {
  margin-left: 15px;

  display: flex;
  align-items: center;
}
.menuIcon {
  display: flex;
  align-items: center;
  height: 100%;
}
.userProfileImgMobile {
  height: auto;
  width: 65px;
  max-height: 65px;
  cursor: pointer;
}
.profileImgCircleMobile {
  height: 64px;
  width: 64px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a30508;
}

.mobileLogoWrapper {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.mobileLogoImg {
  display: block;
  max-width: 89px;
  max-height: 89px;
  border-radius: 50%;
  width: auto;
  height: auto;
}
.mobileLogoTxt {
  font-family: Alfa Slab One, cursive;
  font-size: 22px;
  margin-bottom: 10px;
  color: white;
}
