/* Search Input  */
.searchDiv {
  min-height: 44px;
  background: #fff;
  border: 1px solid #dfe1e5;
  box-shadow: none;
  border-radius: 24px;
  margin: 0 auto;
  width: 638px;
  width: auto;
  max-width: 584px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  padding-right: 5px;
}
.searchInput {
  outline: none;
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border: 0 !important;
}
.searchIcon {
  margin-right: 5px;
  font-size: 25px;
  color: #6b0001;
  cursor: pointer;
}

.searchTypeMenu ul li:hover {
  background-color: #a30508;
}
.txtContainer {
  flex: 4;
  padding-left: 2px;
}
.iconContainer {
  flex: 1;
  padding-left: 5px;
}
.iconContainer .icon {
  color: #fff;
  cursor: pointer;
}

/* END Search Input  */
