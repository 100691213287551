.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #6b0001;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-height: 50px;
}

.brand-title {
  font-size: 1.5rem;
  margin: 0.5rem;
}

.navBar-links ul {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navBar-links li {
  list-style: none;
}
.menuLabel {
  font-size: 12px !important;
  margin-left: 8px;
}
.menuIcon {
  font-size: 15px;
}

.navBar-links li a {
  text-decoration: none;
  color: white;
  padding: 1rem;
  display: block;
  pointer-events: auto;
}
.navBar-links li:hover {
  background-color: #a30508 !important;
}
.navBar-links li ul {
  cursor: pointer;
}
.dd:hover {
  background-color: #a30508 !important;
}

.navBar-links .active {
  background-color: #a30508;
}

.headerWrapper {
  /* pointer-events: none; */
}

.headerWrapper:hover {
  border-bottom: 5px solid #a30508 !important;
}

.toggle-btn {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 23px;
  height: 35px;

  border-radius: 3px;
  margin: 5px;
}
.toggle-btn .bar {
  height: 3px;
  width: 100%;

  border-radius: 10px;
}
.userProfilePopup {
  background-color: #6b0001;
  position: absolute;
  right: 0;
  top: 50px;
  width: 280px;
  z-index: 51;
}
.userProfilePopup ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.userProfilePopup ul li {
  padding: 10px;
  border-bottom: 1px solid #840406;
}

.userProfilePopup ul li:hover {
  background-color: #a30508;
}
/* user profile menu */
.profileImgArrow {
  position: absolute;
  bottom: -5px;
  right: -8px;
  font-size: 30px;
  color: white;
}
.userProfileImgWrapper {
  height: 41px;
  width: 41px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
  /* background-color: #a30508; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.userProfileImg {
  height: auto;
  width: 40px;
  max-height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.profileImgCircle {
  height: 41px;
  width: 41px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 5px;
}

/*END  user profile menu */
/* @media (max-width: 700px) { */
@media (max-width: 1203px) {
  .userProfileMenu {
    display: none;
  }
  .menuLabel {
    font-size: 14px !important;
    margin-left: 5px;
  }
  .menuIcon {
    font-size: 14px;
  }
  .profileImgCircle {
    display: none;
  }
  .toggle-btn {
    display: flex;
    width: 45px;
    padding: 5px;
  }

  .navBar-links {
    display: none;
  }
  .navBar {
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .navBar-links ul {
    width: 100%;
    flex-direction: column;
    position: absolute;
  }
  .navBar-links li {
    text-align: center;
  }
  .navBar-links li a {
    padding: 0.5rem 1rem;
  }
  .navBar-links.active {
    display: flex;
  }
}

/* END NAV BAR */
