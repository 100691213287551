.text-pruple {
  color: #c522ff !important;
}
.text-red {
  color: #c70039 !important;
}

.text-darkestred {
  color: #6b0001 !important;
}
.text-darkred {
  color: #900c3f !important;
}
.text-teal {
  color: #41ffff !important;
}

.text-violet {
  color: #6846fe !important;
}
.text-yellowgreen {
  color: #b5ab10 !important;
}
.text-blackblue {
  color: #0e1a30 !important;
}
.text-blue {
  color: #28abff !important;
}
.text-babyblue {
  color: #0786c1 !important;
}
.text-yellow {
  color: #f1ce07 !important;
}
.text-lightred {
  color: #fb2a29 !important;
}

.text-brown {
  color: #b2540b !important;
}
.text-pink {
  color: #fc45c8 !important;
}
.text-grey {
  color: #babdc0 !important;
}

.text-olive {
  color: #d3ef39 !important;
}
.text-orange {
  color: #eb5905 !important;
}
.text-white {
  color: #fff !important;
}
.text-dark {
  color: #333 !important;
}
.text-black {
  color: #000 !important;
}
