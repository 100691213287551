.bg-pruple {
  background-color: #c522ff !important;
}
.bg-red {
  background-color: #c70039 !important;
}

.bg-darkestred {
  background-color: #6b0001 !important;
}
.bg-darkred {
  background-color: #900c3f !important;
}
.bg-teal {
  background-color: #41ffff !important;
}

.bg-violet {
  background-color: #6846fe !important;
}
.bg-yellowgreen {
  background-color: #b5ab10 !important;
}
.bg-blackblue {
  background-color: #0e1a30 !important;
}
.bg-blue {
  background-color: #28abff !important;
}
.bg-babyblue {
  background-color: #0786c1 !important;
}
.bg-yellow {
  background-color: #f1ce07 !important;
}
.bg-lightred {
  background-color: #fb2a29 !important;
}

.bg-brown {
  background-color: #b2540b !important;
}
.bg-pink {
  background-color: #fc45c8 !important;
}
.bg-grey {
  background-color: #babdc0 !important;
}

.bg-olive {
  background-color: #d3ef39 !important;
}
.bg-orange {
  background-color: #eb5905 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-dark {
  background-color: #333 !important;
}
.bg-black {
  background-color: #000 !important;
}
