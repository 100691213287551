@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./styles/searchBar.css");
@import url("./styles/media.css");
@import url("./styles/navbar.css");
@import url("./styles/mobileNav.css");
@import url("./styles/textColor.css");
@import url("./styles/bgColor.css");
@import url("./styles/borderColor.css");

* {
  box-sizing: border-box;
}
body {
  /* margin: 0;
  padding: 0; */
}
/* .tooltip,
.tooltip-inner {
  background-color: #0786c1 !important;
}
.tooltip-arrow {
  color: #0786c1 !important;
} */
.circle {
  display: flex;
  padding: 6px !important;
  border-radius: 50% !important;
  justify-content: center !important;
  align-items: center !important;
}
* {
  font-family: "Raleway", sans-serif !important;
}
/* .nav-link {
  font-family: "Raleway", sans-serif !important;
  color: white !important;
}

.navbar-toggler {
  color: white !important;
} */
