.border-pruple {
  border-color: #c522ff !important;
}

.border-teal {
  border-color: #41ffff !important;
}

.border-violet {
  border-color: #6846fe !important;
}
.border-yellowgreen {
  border-color: #b5ab10 !important;
}
.border-blackblue {
  border-color: #0786c1 !important;
}
.border-blue {
  border-color: #28abff !important;
}
.border-babyblue {
  border-color: #0786c1 !important;
}
.border-yellow {
  border-color: #f1ce07 !important;
}
.border-red {
  border-color: #fb2a29 !important;
}

.border-brown {
  border-color: #b2540b !important;
}
.border-pink {
  border-color: #fc45c8 !important;
}
.border-grey {
  border-color: #babdc0 !important;
}

.border-olive {
  border-color: #d3ef39 !important;
}
.border-orange {
  border-color: #eb5905 !important;
}
.border-white {
  border-color: #fff !important;
}
.border-dark {
  border-color: #333 !important;
}
.border-black {
  border-color: #000 !important;
}
.border-darkred {
  border-color: #900c3f !important;
}
