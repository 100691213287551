/* media enlarge  */

.enlargeIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 2;
  bottom: 1;
  right: 0;
  background-color: rgba(107, 0, 1, 0.7);
  color: white;
  cursor: pointer;
  z-index: 9;
}
.enlargeIcon {
  color: white;
  font-size: 1.5rem;
}
.modalCloseBtn {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(107, 0, 1, 1);
  color: white;
  cursor: pointer;
  z-index: 1001;
}
/* end media  */
