#alertModal {
  .modal-content {
    background-color: transparent !important;
  }
  .modal-header {
    border-bottom: 0 !important;
  }
  .modal-footer {
    border-top: 0 !important;
  }
}
